.planos-wrapper {
    margin: 32px;
  }
  .title {
    font-family: ProximaNova;
    font-size: 20px;
    text-align: left;
    letter-spacing: 0;
    color: #5B616F;
    opacity: 1;
  }