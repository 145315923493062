@import 'components/base/sass/colors.sass'
@import 'components/base/sass/variables.sass'

body
  background:
    color: $login-blue
    image: url(./../../base/svg/backgrounds/login-base.svg)
    position: center
    repeat: repeat-x
    attachment: fixed

.login
  &-template
    display: flex
    justify:
      content: center
    min:
      height: 100vh
      width: 100vw
    box-sizing: border-box
    padding: 40px 20px

  &-container
    align:
      content: flex-start
      items: flex-start
    display: flex
    flex:
      flow: column wrap
    justify:
      content: center
    max:
      width: 400px
    text:
      align: center
    width: 81vw

  &-logo
    align:
      self: center
    height: auto
    margin:
      bottom: 10%
    width: 45vw
    @media (min-width: $screen-sm-min)
      width: 38%

  &-box
    background:
      color: #fff
    border:
      radius: 20px
    display: flex
    flex:
      direction: column
    width: 100%
    box-sizing: border-box
    padding: 20px

    &__content
      display: flex
      flex: 1
      justify:
        content: center

    &__footer
      display: flex
      flex-direction: column
      align:
        content: center
        items: center
      background:
        color: $login-footer-BackgroundColor
      border:
        bottom:
          left:
            radius: 20px
          right:
            radius: 20px
          display: flex
      flex:
        wrap: wrap
      font:
        size: 16px
      justify:
        content: center
      height: 92px
      width: 100%
