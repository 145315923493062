@import '~normalize.css'

body
  min-width: 100%
  font:
    family: ProximaNova-Regular, system-ui
    size: 10px

  @media (min-width: 720px)
    font:
      size: 12px

a
  text:
    decoration: none

ul
  list:
    style: none
  margin: 0
  padding: 0

button, p, span
  font-family: ProximaNova-Regular
