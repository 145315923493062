@import 'components/base/sass/colors.sass'
@import 'components/base/sass/mixins.sass'
@import 'components/base/sass/variables.sass'

.pagination
  align:
    items: center
  color: $grey5
  display: flex
  font:
    size: 1rem
  justify:
    content: flex-end
  height: 65px
  @media (max-width: 720px)
    display: grid

  @media (max-width: 550px)
    justify:
      content: center

  &__per-page,
  &__size
    margin:
      right: 20px
    @media (max-width: 720px)
      margin-right: 0px
    &__label
      color: $brand-primary
      margin:
        right: 10px

  select
    @include border-box-effect
    cursor: pointer
    height: 40px

  &__navigation
    display: flex
    justify:
      content: flex-end
    width: 100px
    @media (max-width: 550px)
      margin-left: 20px

    &__button
      align:
        items: center
      display: flex
      justify:
        content: center
      height: 40px
      margin:
        left: 10px
      width: 40px

      svg
        height: 20px
        width: 20px

      &--active
        svg
          fill: $brand-primary
        &:hover
          @include border-box-effect
          border:
            radius: 5px
          cursor: pointer

      &--inactive
        opacity: 0.3
        svg
          fill: $grey5
