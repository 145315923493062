.add-departments {
  width: 100%;
  padding: 20px 46px 46px;
}

.heading-main {
  color: gray;
  padding: 1% 0% 0% 3%;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #909090;
  font-family: 'ProximaNova-Extrabld';
}

.add-department-form {
  padding: 1% 3% 0% 3%;
}

.add-department-buttons {
  padding: 16% 0% 1% 63% !important;
}

.add-department-flex {
  display: flex;
  padding: 22% 0% 5% 29%;
}

.btn-align {
  margin: 0% 3% 0% 0%;
}

.add-employee-buttons {
  margin: 0% 0% 0% 5% !important
}