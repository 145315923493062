@import 'components/base/sass/colors.sass'
@import 'components/base/sass/mixins.sass'
@import 'components/base/sass/variables.sass'

.contributors-page
  &__content
    padding: 0 $page-content-padding-side $page-content-padding-side
  &__fab-button
    margin: -3% 0% 0% 96%

  &__list
    &__icon
      fill: $grey5
      height: 20px
      margin:
        right: 10px
      width: 20px

    &__header
      display: flex
      justify:
        content: space-between
      padding: 0px 0 15px

      &__options
        align:
          items: flex-end
        display: flex

        &__button
          align:
            items: center
          color: $brand-primary
          cursor: pointer
          display: flex
          font:
            size: 1rem
          margin:
            right: 10px

      &__buttons
        .btn ~ .btn
          margin:
            left: 10px
