@import 'components/base/sass/colors.sass'
@import 'components/base/sass/mixins.sass'
@import 'components/base/sass/variables.sass'

.widget-performance
  @media (max-width: 720px)
    width: 50%
    height: auto
    margin-right: 10px
  @media (max-width: 550px)
    width: 100%

  &__container
    padding: 0 5px
    width: 100%

  .apexcharts-tooltip
    box:
      shadow: 0
.chart_box
  background:
    color: #000
  border:
    radius: 20px
  height: 30px
  line-height: 30px
  opacity: 0.65
  padding: 0 15px

  span
    color: #FFF