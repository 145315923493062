@import 'components/base/sass/colors.sass'
@import 'components/base/sass/variables.sass'

.login
  &-box
    &__footer

      &__link
        color: $login-footer-link
        font:
          family: "ProximaNova-Bold"
        text:
          decoration: underline

  &-sign-in
    display: flex
    padding: 40px 0 0
    width: 100%

    &__forgot
      text-align: center
      padding: 20px 0
      font-size: 1.2em
      a
        font-weight: bold
        color: $grey6
        text-decoration: underline

    &__form
      display: flex
      flex: 1
        direction: column
      justify:
        content: flex-start

      &__title
        font:
          family: "ProximaNova-Extrabld"
          size: 32px //TODO with rem
        margin: 0 0 10px

      &__desc
        font:
          family: "ProximaNova-Regular"
          size: 16px //TODO with rem
        margin: 0 0 40px

        b
          font:
            family: "ProximaNova-Bold"

    &__aux
      color: $login-error
      display: flex
        wrap: wrap
      height: 47px
      padding: 8px

    &__icon-error
      fill: $login-error
      height: 13px
      margin:
        right: 6px
      width: 13px

    &__error
      &__container
        align:
          content: flex-start
          items: flex-start
        display: flex
        flex: 1
          direction: column

      &__label
        font:
          family: "ProximaNova-Bold"
        text:
          transform: uppercase

  &-btn
    background:
      color: $login-button
    border: 0
      radius: 8px
    box:
      shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
    color: #FFF
    cursor: pointer
    font:
      family: "ProximaNova-Regular"
      size: 24px // TODO with rem
    height: 54px
    text:
      align: center
    width: 100%

    &:active
      background:
        color: $login-button-pressed
      transform: translateY(2px)


div.login-input
  height: 54px
  margin:
    bottom: 23px
