@import 'components/base/sass/colors.sass'
@import 'components/base/sass/variables.sass'

.page-search
  background:
    color: #FFF
  border:
    bottom: 2px solid $ice
  height: $header-line-toppage-height

  &__form
    align:
      items: center
    display: flex
    height: 100%
    padding: 0 $page-content-padding-side
    position: relative

    &__icon
      cursor: pointer
      fill: $blue3
      width: 28px

    &__input

      &-container
        align:
          items: center
        display: flex
        flex:
          direction: column
        justify:
          content: flex-start
        margin:
          left: 16px
        width: 30%

        &__elem
          border: 0
          caret:
            color: $brand-primary
          font:
            size: 0.8rem
          outline: 0
          padding: 5px 0
          width: 100%

          &::placeholder
            color: $blue2

          &:focus ~ span
            width: 100%

        &__line
          align:
            self: flex-start
          background:
            color: $brand-primary
          height: 2px
          transition: 0.5s
          width: 0
