@import 'components/base/sass/colors.sass'
@import 'components/base/sass/mixins.sass'
@import 'components/base/sass/variables.sass'

.widget-base__header__aux_span
  @media (max-width: 720px)
    display: none 
.widget-base
  @include border-box-effect
  border:
    radius: 5px
  background:
    color: #fff
  height: 100%

  &__header
    align:
      items: center
    border:
      bottom: 2px solid $grey3
    display: flex
    justify:
      content: space-between
    height: 54px
    padding: 0 15px

    &__title
      color: $widget-title
      font:
        family: "ProximaNova-Bold"
        size: 18px
      @media (max-width: 720px)
        font-size: 12px
  
    &__aux
      align:
        items: center
      display: flex
      justify:
        content: space-between
      margin-left: 5px  
   

  &__content
    padding:
      top: 25px