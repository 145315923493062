.header1{
    width: 148px;
    height: 24px;
    font-family: ProximaNova;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #5b616f;          
}

.content5{
  width: 605px;
  height: 17px;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #909090;
}
.cost-input{
    width: 256px;
    height: 48px;
    border-radius: 8px;
    border: solid 2px #ebeff2;
    background-color: #ffffff;
}
.content-input{
  width: 444px;
  height: 48px;
  border-radius: 8px;
  border: solid 2px #ebeff2;
  background-color: #ffffff;
  padding-left: 1%;
}
.inserir{
  width: 104px;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #308bcb;
}
.paper-text{
  width: 101px;
  height: 17px;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #99a6b7;
}
.paper-text1{
  width: 39px;
  height: 17px;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: right;
  color: #909090;
  padding-left: 83%;
}
.paper-icon{
  width: 39px;
  height: 17px;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: right;
  color: #909090;
  padding-left: 88%;
}
.insert-text{
  width: 239px;
  height: 17px;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #909090;
}
.Delete-Icon{
  width: 40px;
  height: 17px;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}