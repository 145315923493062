@import 'components/base/sass/colors.sass'
@import 'components/base/sass/variables.sass'

.sidebar-template
  display: flex
  // grid:
  //   template:
  //     columns: 17% 1fr
  // height: 100vh
  // overflow: hidden

  @media (min-width: $screen-sm-min)
    grid:
      template:
        columns: 30% 1fr

  @media (min-width: $screen-md-min)
    grid:
      template:
        columns: 25% 1fr

  @media (min-width: $screen-lg-min)
    grid:
      template:
        columns: 20% 1fr

  @media (min-width: $screen-xl-min)
    grid:
      template:
        columns: 18% 1fr

  @media (min-width: $screen-xxl-min)
    grid:
      template:
        columns: 15% 1fr

  @media (min-width: $screen-xxxl-min)
    grid:
      template:
        columns: 14% 1fr

.login-logo
  align:
    self: center
  height: auto
  margin:
    bottom: 10%
  width: 45vw
  @media (min-width: $screen-sm-min)
    width: 38%  
    
#container
  @media (max-width: 720px)
    zoom: 75%

#subContainer
  @media (max-width: 720px)
    margin-top: 80px
    zoom: 64%