.confirm-modal {
  border-radius: 20px;
  box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  align-self: center;
}

.close-btn {
  position: absolute;
  right: 15px;
  top: 30px;
  bottom: 16px;
}
.modal-dialog-header {
  margin-top: 64px;
  font-family: ProximaNova-Extrabld;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
  align-items: center;
  align-self: center;
}
.modal-confirm-bgImage {
  margin: 0 35% 20px 35%;
}
.MuiDialogActions-root {
  margin-top: 42px;
  justify-content: center;
}

.cancel-btn {
  width: 160px;
  height: 54px;
  border-radius: 8px;
  border: solid 1px #909090;
  font-family: ProximaNova-Extrabld;
}
.continue-btn {
  width: 160px;
  height: 54px;
  border: 1px solid #000;
  font-family: ProximaNova-Extrabld;
  border-radius: 8px;
}
