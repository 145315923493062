
// Viewports

$screen-sm-min: 768px
$screen-md-min: 992px
$screen-lg-min: 1200px
$screen-xl-min: 1400px
$screen-xxl-min: 1600px
$screen-xxxl-min: 1800px

// Header

$header-line-toppage-height: 78px
$header-padding-side: 20px

// Page concent
$page-content-padding-side: 46px