
.greenDot{
	background: #30cb8f;
	border-radius: 69%;
	height: 6px;
	margin: 0 3px 1px 0;
	vertical-align: middle;
	display: inline-block;
	width: 6px;
}

.redDot{
	background: #FF3333;
	border-radius: 69%;
	height: 6px;
	margin: 0 3px 1px 0;
	vertical-align: middle;
	display: inline-block;
	width: 6px;
}

.yellowDot{
	background: #FFFC33;
	border-radius: 69%;
	height: 6px;
	margin: 0 3px 1px 0;
	vertical-align: middle;
	display: inline-block;
	width: 6px;
}

.list-grid {
	border-radius: 12px;
}

.list-grid__table__item__attribute {
	color: #99a6b7;
}

.list-grid__table__item__attribute--name{
	color: #5b616f;
}

.depart{
	color: #5b616f;
}

.monthly{
	color: #5b616f;
}

.limit{
	color: #5b616f;
}

.contributors-page__list__header__options__button{
	font-weight: bold;
}

.contributor-heading {
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: #5b616f;
	margin-bottom: 40px;
}

.contributors-page__list__header {
	margin: -3% 0 0 0;
}

.contributors-page__list__icon {
	fill: #5b616f;
	height: 20px;
	margin-right: 10px;
	width: 20px;
	stroke: #5b616f;
	stroke-width: 20;
}