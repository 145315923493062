@import 'components/base/sass/colors.sass'
@import 'components/base/sass/mixins.sass'
@import 'components/base/sass/variables.sass'

@mixin list-grid-item-default
  border:
    bottom:
      color: $grey
      style: solid
  display: grid
  grid:
    template:
      columns: 1.5fr 6fr 5fr 4fr 3fr 3fr 2fr 2fr
  list:
    style: none


.list-grid
  @include border-box-effect

  background:
    color: #fff
  border:
    radius: 5px
  display: grid

  &__table
    font:
      size: 1rem
    margin: 0
    padding: 0

    &__item
      @include list-grid-item-default
      border:
        bottom:
          width: 1px
      cursor: pointer
      height: 65px

      &:hover
        @include border-box-effect

      &:last-child
        border:
          bottom:
            width: 0

      &__attribute
        align:
          items: center
        color: $grey5
        display: flex
        justify:
          content: center

        &--name
          color: #000

        &--checkbox,
        &--options
          justify:
            content: center

        &--options
          font:
            size: 1.2rem
          letter-spacing: 0.1em

    &__header
      @include list-grid-item-default
      border:
        bottom:
          width: 2px
      color: $grey4
      font:
        family: "ProximaNova-Bold"
      height: 65px

      > div
        justify:
            content: center