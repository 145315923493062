
// Brand Colors
$brand-primary: #308bcb
$brand-secondary: #091c3a

$brand-primary2: #3ab8f0

// Layout Colors

$grey: #f2f3f5
$grey2: #bfbfbf
$grey3: #e4e0da
$grey4: #7d8da3
$grey5: #5b616f
$grey6: #5c666f

$ice: #ebeff2

$blue: #98a7bc
$blue2: #7185a2
$blue3: #b9c7db
$blue4: #000615
$blue5: #94a6bf

// Login - Colors
$login-blue: #09173A
$login-button: #009ce2
$login-button-pressed: #0b6e9a
$login-footer-BackgroundColor: #f2f2f2
$login-footer-link: #159be2
$login-error: #ea435b

// Dashboard - Widgets

$widget-title: #0a1d31
