.add-contributors {
  width: 91%;
  margin: 0% 0% 0% 4%;
  padding-bottom: 20px;
}

.heading-main {
  color: gray;
  padding: 2% 0% 2% 3%;
  width: 100%;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #909090;
}

.add-employee-form {
  padding: 1% 3% 0% 2%;
}

.add-employee-buttons {
  margin: 0% 0% 0% 4% !important;
  padding: 16% 0% 1% 63% !important;
}

.add-employee-flex {
  display: flex;
  padding: 22% 0% 5% 29%;
}

.btn-align {
  margin: 0% 3% 0% 0%;
}

.paperSize {
  border-radius: 18px;
}

.new-input-add {
  border-radius: 16px;
  box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #d41010;
}