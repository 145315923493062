.planos-wrapper {
  margin: 32px;
}
.planos-title {
  font-family: ProximaNova;
  font-size: 20px;
  font-weight: bold;
  color: #5b616f;
}

.planos-filter-row {
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #308bcb;
}
.planos-filter-icon {
  width: 14.2px;
  height: 16px;
  padding-right: 11px;
}
.planos-filter-wrapper {
  display: flex;
  flex-direction: row;
  padding: 34px 0 17px 0;
}
.table-header {
  display: flex;
}
.planos-table {
  height: 579px;
  border-radius: 16px;
  box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}
.add-planos-icon {
  position: relative;
  bottom: 0;
  margin-left: 97%;
  margin-top: -1%;
   width: 64px;
  height: 64px;
  font-size: 30px;
}
.MuiIcon-fontSizeLarge{
font-size: 180;
}

/*isolar criar um css generico para form*/
.add-contributors {
  width: 80%;
  margin: 0% 0% 0% 4%;
  padding-bottom: 20px;
}

.heading-main {
  color: gray;
  padding: 2% 0% 2% 3%;
  width: 100%;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #909090;
  font-family: 'ProximaNova-Extrabld';
}

.add-employee-form {
  padding: 1% 3% 0% 3%;
}

.add-employee-buttons {
  margin: 0% 0% 0% 4% !important;
  /* padding: 5% 0% 1% 75%; */
}

.add-employee-flex {
  display: flex;
  padding: 22% 0% 5% 29%;
}

.btn-align {
  margin: 0% 3% 0% 0%;
}

.paperSize {
  border-radius: 18px;
}

.new-input-add {
  border-radius: 16px;
  box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #d41010;
}

/*temp*/

.weekDays-selector input {
  display: none!important;
}


.weekDays-selector input[type=checkbox] + label {
  display: inline-block;
  border-radius: 50%;
  color:#99A6B7;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #EBEFF2;
  height: 32px;
  width: 32px;
  margin-right: 3px;
  line-height: 32px;
  font-size:12px;
  text-align: center;
  cursor: pointer;
}

.weekDays-selector input[type=checkbox]:checked + label {
  background: #308bcb;
  color: #ffffff;
}