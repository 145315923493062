@import 'components/base/sass/colors.sass'
@import 'components/base/sass/mixins.sass'
@import 'components/base/sass/variables.sass'

.widget-trips

  &__content
    padding:
      bottom: 25px
      left: 25px
      right: 25px

  &__resume
    @include border-box-effect
    border:
      radius: 4px
    display: flex
    flex:
      direction: column
    height: 233px
    height: 317px
    @media (max-width: 720px)
      height: 317px

    &__header
      border:
        bottom: 2px solid $grey3
      display: flex
      font:
        family: "ProximaNova-Bold"
        size: 1rem
      height: 70px
      @media (max-width: 720px)
        flex-direction: column
      @media (max-width: 550px)
        height: 124px

      &__content
        align:
          items: center
        color: $blue4
        display: flex
        flex: 1
          direction: column
        justify-content: center

        > span
          color: $grey6
          font:
            family: "ProximaNova-Regular"
            size: 0.9rem

    &__content
      align:
        items: center
      display: flex
      align-items: center
      justify-content: center
      flex: 1
      padding: 0 18px
      overflow: hidden
      @media (max-width: 720px)
        overflow: scroll

      &--carousel
        padding: 0

      &--size
        &-1,
        &-2,
        &-3
          justify:
            content: center

        &-4
          justify:
            content: space-between
    &__car
      align:
        items: center
      display: flex
      flex:
        direction: column
      justify:
        content: center
      height: 125px
      margin: 0 10px

      &__name
        color: $blue4
        font:
          family: "ProximaNova-Bold"
          size: 0.88rem
        margin:
          bottom: 8px
          top: 16px

      &__trips
        color: $grey6
        font:
          family: "ProximaNova-Regular"

  &__last-trips
    padding:
      top: 30px

    &__title
      color: $blue4
      font:
        family: "ProximaNova-Bold"
        size: 1rem
      margin:
        bottom: 30px

    &__trip
      @include border-box-effect
      align:
        items: center
      border:
        radius: 4px
      display: flex
      align-items: center
      justify-content: center
      height: 155px
      padding: 14px
      @media (max-width: 720px)
        padding: 0px
        height: 230px
        flex-direction: column

      &:not(:last-child)
        margin:
          bottom: 30px

      &__user
        display: flex
        width: 250px
        @media (max-width: 720px)
          display: flex
          width: 100%
          padding: 10px
        &__desc
          display: flex
          flex: 1
          direction: column
          padding:
            left: 14px
            top: 8px
          @media (max-width: 720px)
            border-bottom: 2px solid #e4e0da
            width: 100%

        &__avatar
          border:
            radius: 50%
          height: 61px
          width: 61px

        &__name
          color: $grey6
          font:
            family: "ProximaNova-Regular"
            size: 0.9rem
          margin:
            bottom: 5px

        &__btn
          align:
            items: center
          background:
            color: $login-button
          border: 0
            radius: 5px
          box:
            shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
          color: #FFF
          cursor: pointer
          display: flex
          font:
            family: "ProximaNova-Regular"
            size: 0.8rem
          justify:
            content: center
          height: 22px
          text:
            align: center
          width: 85px
          display: none
          &:active
            background:
              color: $login-button-pressed
            transform: translateY(1px)
      &__detail
        display: flex
        @media (max-width: 720px)
          padding: 10px
          width: 100%
          height: 40%
        &__car
          color: $blue4
          font:
            family: "ProximaNova-Bold"
            size: 0.88rem
          @media (max-width: 720px)
            margin-bottom: 15px

        &__desc
          display: flex
          flex:
            direction: column
          justify:
            content: space-between
          padding: 3px 0
          padding-left: 16px
          @media (max-width: 720px)
            padding: 5px
            width: 100%
        &__duration
          color: $blue5
          margin:
            right: 10px

        &__price

  &__pagination
    margin:
      top: 20px
.BrainhubCarousel
  width: 633px

  &__arrows
    @include border-box-effect
    background:
      color: transparent
    border:
      radius: 50%
    padding: 17px

    &:hover
      background:
        color: transparent

      span
        border:
          color: #009ce2

    span
      border:
        color: #e4e0da
      padding: 4px

  &__arrowLeft
    margin:
      left: 5px

  &__arrowRight
    margin:
      right: 5px
