.header2{
    width: 108px;
    height: 24px;
    font-family: ProximaNova;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #5b616f;
}
.content2{
  width: 562px;
  height: 17px;
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #909090;
}
.enable-text{
    width: 30px;
    height: 15px;
    font-family: ProximaNova;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
    padding-right: 9px;
}
