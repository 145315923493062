@import 'components/base/sass/colors.sass'
@import 'components/base/sass/mixins.sass'
@import 'components/base/sass/variables.sass'

.dashboard-page
  padding: 50px 

.widgets-container
  display: grid
  grid:
    column:
      gap: 30px
    row:
      gap: 10px
    template:
      areas: "widget-left widget-right"
      columns: 2fr 1fr
  @media (max-width: 720px)
    display: contents
  &--left
    grid:
      area: widget-left

  &--right
    grid:
      area: widget-right
    @media (max-width: 720px)
      display: flex
    @media (max-width: 550px)
      display: block
  &--left,
  &--right
    > *
      margin:
        bottom: 30px